.AsyncLoadingAnimation {
    background-color: #aaa;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e0ebff+0,e0ebff+35,f6f9ff+50,e0ebff+65,e0ebff+100 */
    background: #e0ebff;
    background: -moz-linear-gradient(left,  #e0ebff 0%, #e0ebff 35%, #f6f9ff 50%, #e0ebff 65%, #e0ebff 100%);
    background: -webkit-linear-gradient(left,  #e0ebff 0%,#e0ebff 35%,#f6f9ff 50%,#e0ebff 65%,#e0ebff 100%);
    background: linear-gradient(to right,  #e0ebff 0%,#e0ebff 35%,#f6f9ff 50%,#e0ebff 65%,#e0ebff 100%);
    background-size: 350%;
    background-position: 0% 50%;
    animation: myAnim 1s ease-in-out 0s infinite normal forwards;
    border-radius: .5rem;
}

@keyframes myAnim {
	0% {
		background-position: 100% 50%;
	}

	100% {
		background-position: 0% 50%;
	}
}